import styled from 'styled-components'

const Message = styled.div`
  h2 {
    white-space: nowrap;
  }
  p {
    white-space: nowrap;
  }
`

export default Message
