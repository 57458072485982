import styled from 'styled-components'

const Links = styled.div`
  position: absolute;
  right: 64px;
  bottom: 16px;
  display: flex;
  gap: 32px;
`

export default Links
