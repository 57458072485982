import { configureStore } from '@reduxjs/toolkit'
import { dictionariesReducer } from './slices/dictionaries'
import { userReducer } from './slices/user'

const store = configureStore({
  reducer: {
    user: userReducer,
    dictionaries: dictionariesReducer
  }
})

export type RootState = ReturnType<typeof store.getState>

export default store
