import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUserSession } from '../api/account'
import { userActions } from '../store/slices/user'

const useUserSession = () => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const fetchSession = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getUserSession()
      dispatch(userActions.setUser(response))
    } catch (e) {
      dispatch(userActions.setUser(null))
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    fetchSession()
  }, [fetchSession])

  return isLoading
}

export default useUserSession
