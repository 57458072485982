import axios from 'axios'

const instance = axios.create()

instance.interceptors.request.use(req => {
  const antiforgeryCookieName = 'X-XSRF-TOKEN'
  const antiforgeryMethods = ['POST', 'PUT', 'PATCH', 'DELETE']

  const [antiforgeryToken] = document.cookie
    .split('; ')
    .filter(x => x.startsWith(`${antiforgeryCookieName}=`))
    .map(token => token.replace(`${antiforgeryCookieName}=`, ''))

  if (
    antiforgeryToken &&
    req.headers &&
    antiforgeryMethods.includes(req.method?.toUpperCase() || '')
  ) {
    req.headers['RequestVerificationToken'] = antiforgeryToken
  }

  return req
})

export default instance
