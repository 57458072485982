import { PropsWithChildren, useState } from 'react'

export interface ImageValidatorProps extends PropsWithChildren {
  imageSrc: string
}

export const ImageValidator = ({ imageSrc, children }: ImageValidatorProps) => {
  const [loaded, setLoaded] = useState<boolean | null>(null)
  if (loaded === null)
    return (
      <img
        src={imageSrc}
        width="0"
        alt="test"
        onError={() => setLoaded(false)}
        onLoad={() => setLoaded(true)}
      />
    )
  if (loaded === true) return <>{children}</>
  return <></>
}
